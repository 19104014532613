:root {
  --main-color: #663300;
  --bg-color: #87CEFA;
  --text-color: #000080;
  --text-font: Tahoma;
  --text-hover-color: #fff7ef;
  --column-count: 2;
  --row-count: 2;
}

#root {
  width: 100%;
}

body {
  margin: 0;
  font-family: var(--text-font);
  color: var(--main-color);
  background-color: var(--bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Make the container a flex container */
  display: flex;
  /* Center the flex items horizontally */
  justify-content: center;
  /* Wrap the flex items to multiple lines if needed */
  flex-wrap: wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  grid-row: 1 / var(--row-count);
  grid-column: 1 / var(--column-count);
  padding: 1rem;
}

.footer {
  grid-row: 3 / 4;
  grid-column: 1 / var(--column-count);
  padding: 1rem;
}

/* Select the hr element */
hr {
  /* Set the width to 100% of the screen */
  width: 100%;
  /* margin-left: -300px;
  margin-right: -300px; */
  /* Set the height to 3 pixels */
  height: 3px;
  /* Set the background color to navy */
  background-color: var(--text-color);
  /* Remove the border */
  border: none;
  /* Set the top and bottom margins to 1em (the font size) */
  margin-top: 1em;
  margin-bottom: 1em;
}


/* Select the hr element that is the first child of its parent */
hr:first-child {
  /* Position the line below the header */
  margin-top: 10px;
}

/* Select the hr element that is the last child of its parent */
hr:last-child {
  /* Position the line above the footer */
  margin-bottom: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--text-font);
  color: var(--main-color);
  /* Center the flex items horizontally */
  justify-content: center;
}

/* Select the p element */
p {
  /* Set the bottom margin to 20px */
  margin-bottom: 0px;
  margin-block-end: 1em;
  margin-block-start: 1em;
  text-align: center;
}

.left-justify {
  margin-bottom: 0px;
  margin-block-end: 1em;
  margin-block-start: 1em;
  text-align: left;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  padding: 20px;
}

nav ul {
  list-style: none;
}

nav li {
  display: inline-block;
  border-right: 2px solid var(--main-color);
  margin: 5px;
}

nav a {
  color: var(--text-color);
  text-decoration: none;
  /* Add 10px of padding to the left and right sides */
  padding: 0 10px;
}

a {
  color: var(--text-color);
  text-decoration: none;
  /* Add 10px of padding to the left and right sides */
  padding: 0 0px;
}

a:hover {
  color: var(--text-hover-color);
}

nav a:hover {
  color: var(--text-hover-color);
}

/* Select the last list item and remove the right border */
nav li:first-child {
  border-left: 2px solid var(--main-color);
}

/* Select the last list item and remove the right border */
nav li:last-child {
  border-right: 2px solid var(--main-color);
}

/* This is the CSS for the layout */
.container {
  display: flex; /* Use flexbox for the parent container */
}

/* This is the CSS for the layout */
.app-container {
  width: 100%;
}

.link-list {
  list-style: none; /* Remove the bullet points */
  margin: 0; /* Remove the margins */
  padding: 0; /* Remove the padding */
  width: 200px; /* Set the width of the list */
  background-color: var(--bg-color); /* Set the background color of the list */
}

.link-list li {
  padding: 10px; /* Add some padding to the list items */
}

.link-list li a {
  text-decoration: none; /* Remove the underline */
  color: var(--text-color); /* Set the color of the links */
}

.link-list li a:hover {
  text-decoration: none; /* Remove the underline */
  color: var(--text-hover-color); /* Set the color of the links */
}

.content {
  flex: 1; /* Make the content fill the remaining space */
  padding: 20px; /* Add some padding to the content */
}

.sidebar {
  padding: 1rem;
}

.main {
  padding: 1rem;
  justify-self: start;
}

.grid-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-rows: minmax(0, 1fr);
}

.sub-site-content {
  grid-row: 2 / 3;
  grid-column: 1 / var(--column-count);
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr minmax(0, 1fr) 1fr;
  gap: 1rem;
  padding: 1rem;
}

.active {
  font-weight: bold;
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: scroll;
  max-width: 100%;
}

.left-justify ul {
  margin-left: 20px; /* Adjust the value as needed */
  padding-left: 20px; /* Adjust the value as needed */
}

.left-justify ol {
  margin-left: 20px; /* Adjust the value as needed */
  padding-left: 20px; /* Adjust the value as needed */
}

/* Container for the conversation */
.conversation {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

/* Style for the text bubbles */
.bubble {
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 80%;
}

/* Style for the left-aligned bubbles */
.left {
  background-color: #f1f1f1;
  align-self: flex-start;
}

/* Style for the right-aligned bubbles */
.right {
  background-color: #0084ff;
  color: white;
  align-self: flex-end;
}

.lazyload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust as needed */
}
